import React, {useState, useEffect} from "react"
import CancelIcon from '../global/cancel_icon'
import BannerBars from '../icons/banner_bars'

const IndustryModal = (props) => {
  const {industry, active} = props
  const mentors = industry.mentors.length > 3 ? industry.mentors.slice(0, 3) : industry.mentors
  const eng = props.language==="english"
  useEffect(()=>{  
    if(active){
      document.querySelector("html").style.overflow ="hidden"
    } else {
      document.querySelector("html").style.overflow ="auto"
    }
  }, [active])
  var modalImage = industry.modalImage && industry.modalImage.localFile ? industry.modalImage.localFile.publicURL : ""
  return(
    <div className={`modal-container ${active&&"active"}`}>
      {industry&&<div className={`industry-modal ${active&&"active"}`}>
        <div>
          <BannerBars />
          <div>
            <h1 className="page-title left">{eng?industry.name:industry.nameFrench}</h1>
            <p>{eng?industry.modalSubtitle:industry.modalSubtitleFrench}</p>
          </div>
          <p>{eng?industry.description:industry.descriptionFrench}</p>
          <div className="industry-modal-bottom-section">
            <div>
              <h2 className="section-title">{eng?industry.name:industry.nameFrench} Mentors</h2>
              <a href={eng?`/mentors-en?industry=${industry.strapiId}`:`/mentors?industry=${industry.strapiId}`}>{eng?"View All":"Voir tout"}</a>
            </div>
            <div>
              {mentors.map((m)=>{
                var profileImage = m.profilePic && m.profilePic.localFile ? m.profilePic.localFile.publicURL : ""
                return(
                <a href={`/mentors?mentor=${m.id}`} style={{backgroundImage:`url("${profileImage}")`}} key={m.id} className="modal-mentor-card">
                  <div>
                    <p>{m.name}</p>
                    <p>{m.company}</p>
                  </div>
                </a>
                )
                
              })}
            </div>
          </div>
        </div>
        <div role="img" className="industry-modal-image" style={{backgroundImage:`url("${modalImage}")`}}>
        </div>
        <button onClick={props.clearModalActive}>
          <CancelIcon />
        </button>
      </div>}
    </div>
  )
}

export default IndustryModal